* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  text-align: center;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 1px 0;
}

body {
  background-color: #28161C;
}

table {
  width: 100%;
}

.article {
  display: grid;
  min-height: calc(100vh - 1em);
  width: calc(100% - 2.5em);
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: auto auto auto;
  grid-template-areas: 
    "map map bus bus"
    "map map bus bus"
    "weather precipitation date time";
  gap: 0.5em;
  padding: 0.5em;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  align-items: center;
  justify-content: space-around;
  background-color: black;
  border-radius: 4px;
  color: white;
}

#weatherCard {
  grid-area: weather
}

#bicycleCard {
  grid-area: bicycle
}

#busCard {
  grid-area: bus;
  align-items: initial;
}

#precipitationCard {
  grid-area: precipitation;
}

#sunCard {
  grid-area: sun
}

#timeCard {
  grid-area: time
}

#dateCard {
  grid-area: date
}

#mapCard {
  grid-area: map;
  padding: 0 0 0.5rem 0;
}

::-webkit-scrollbar {
  display: none;
}

.buses {
  display: flex;
  max-width: 100%;
  overflow-x: scroll;
  margin: 0.5rem 0;
}

.bus {
  background-color: #F9F871;
  border-radius: 2em 0 0 0;
  border: 1px solid black;  
  padding: 0.3em;
  margin: 1em;  
  min-height: 3em;
  min-width: 5rem;
  color: black;
}

.rightText {
  text-align: right;
}

.leftText {
  padding-left: 1em;
  text-align: left;
}

.realtime {
  color: yellow;
}

.sunSymbol {
  height: 3em;
  width: 3em;
}

.weatherSymbol {
  height: 6em;
  width: 6em;
}

.white {
  color: white
}

.time {
  font-size: 5em;
}

.precipText {
  font-size: 3em;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  min-height: 400px;
}

.citybike-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  background-color: #e02244;
  border: 1px solid black;
}

.scooter-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  background-color: purple;
  color: black;
  border: 1px solid black;
  border-radius: 50%;
}

.voi-icon {
  background-color: #f56c64;
  color: white;
}

.tier-icon {
  background-color: #69d2aa;
}

.lime-icon {
  background-color: #56b949;
}

.bolt-icon {
  background-color: #35d187;
}

@media screen and (max-width: 900px) {
  .article {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100vw;
    gap: 0;
    padding: 0;
  }

  .card {
    border-radius: 0%;
    border-bottom: 1px solid gray;
  }
}